
$(document).ready(function(){


    // Mobile navi
    $('.hamburger').click(()=> {
        $('.hamburger').toggleClass("is-active")
        $('.main-navigation').toggleClass("main-navigation--active");
        $('.main-header').toggleClass("main-header--active-navi");

        $('.main-navigation-overlay').toggle()
        $('body').toggleClass('lock-body')
        
    });



     //Show Subnavi
     $('.main-navigation__nav-link').click(function() {
        $('.hamburger').removeClass("is-active")
        $('.main-navigation').removeClass("main-navigation--active");
        $('.main-header').removeClass("main-header--active-navi");

        $('.main-navigation-overlay').toggle()
        $('body').removeClass('lock-body')
     })


    function hideSearchBar () {
        $('.searchbar').hide();
    }


    // Check Size of Window
    function checkWidth() {
        var windowsize = $(window).width();
        return windowsize
    }



    /////////////////////////////////START  Search Bar /////////////////////////////////
/* $('.main-header__search-button').click(()=> {
    console.log("click");
    $('.navbar__searchbar').toggleClass("navbar__searchbar--active");

    $(this).toggleClass('main-header__search-button--active')
}) */

// Mobile Searchbar

   // Searchbar Start

// Show searchbar
$('.searchbar').hide();

$('.main-header__search-button').click(()=>{
    //Close NAVi
    $('.main-navigation__nav-link--active').removeClass('main-navigation__nav-link--active');
    $('.main-navigation__nav-point--active').removeClass('main-navigation__nav-point--active');
    if (checkWidth() < 960) {
        $('.hamburger').removeClass("is-active")
        $('.main-navigation').removeClass("main-navigation--active");
        
        $('.main-navigation-overlay').hide()
        $('body').removeClass('lock-body')

    }
    //ENDE CLOSE NAVI
    
    $('.main-header__search-button').toggleClass('main-header__search-button--active')
     $('.navbar-mobile__container').removeClass("navbar-mobile__container--active");
    $('.navbar-mobile__hamburger').removeClass('is-active');
    $('.overlay').hide();
    $('.searchbar').slideToggle('fast');
    $('.navbar-mobile__icon--hamburger').removeClass("navbar-mobile__icon--hamburger-active")

})



//Close
$('.searchbar__close').click(()=>{
    $('.main-header__search-button').removeClass('main-header__search-button--active')
    $('.searchbar').slideUp(('fast'));
    
});


/////////////////////////////////ENDE  Search Bar /////////////////////////////////


})