  //////////////////READ MORE ////////////////////
  const textMore= 'Mehr anzeigen';
  const textLess = 'Weniger anzeigen'

  $(document).ready(function () {
  $('.show-more__button').click(function() {
    if($(this).closest('.show-more').hasClass('show-more--active')){
        $(this).closest('.show-more').find('.show-more__more-text').slideUp('slow')
        $(this).find('span').text(textMore)
        $(this).closest('.show-more').removeClass('show-more--active')
    }

    else {
        $(this).find('span').text(textLess)
        $(this).closest('.show-more').find('.show-more__more-text').slideDown('slow')
        $(this).closest('.show-more').addClass('show-more--active')

    }
    
})

  })