
$( document ).ready(function() {

    // --------------  GALLERY Slider ---------- //


    // Start Loop
    $('.rezept-slider__container').each(function (key, item) {

        var sliderIdName = 'rezept-slider__container' + key;
        var dotsName = 'rezept-slider__dots' + key;
        var prevArrowName = 'rezept-slider__arrow--prev' + key;
        var nextArrowName = 'rezept-slider__arrow--next' + key;

        this.id = sliderIdName;
        $('.rezept-slider__dots')[key].id = dotsName;
        $('.rezept-slider__arrow--prev')[key].id = prevArrowName;
        $('.rezept-slider__arrow--next')[key].id = nextArrowName;



        //var data = $(this).find('.slick-active').data('title');

        var sliderId = '#' + sliderIdName;
        var dotsID = '#' + dotsName;
        var prevArrowID = '#' + prevArrowName;
        var nextArrowID = '#' + nextArrowName;

        $(sliderId).slick({
            autoplay: true,
            autoplaySpeed: 6000,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            arrows: true,
            dots: true,
            appendDots: $(dotsID),
            prevArrow: $(prevArrowID),
            nextArrow: $(nextArrowID),
        });




    });
    //Ende loop

    $(window).resize(function () {
        $('.rezept-slider__container__container').slick('resize')
    
    });


    // --------------  TEASER BOX Slider ---------- //




    


})
