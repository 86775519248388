/* $(document).ready(function () {
    console.log("here")
$( window ).load(function() {
    
})

})
 */
$(window).on('load', function(){ 
    $('.skeleton-container').hide()
    $('.container').fadeIn()
  
});

   
