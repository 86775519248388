$(document).ready(function () {

   /////////////////// FUNKTIONEN ///////////////////
    // Check Size of Window
    function checkWidth() {
        var windowsize = $(window).width();
        return windowsize
    }
    /////////////////// //////////////////



    $('.ingridient-block__cta-button').click(function() {
        let modalID = $(this).data('modal')
        $('#' + modalID).show()
        $('.ingridient-modal__overlay').show()
    })
    $('.ingridient-modal__close').click(function() {
        $('.ingridient-modal').hide()
        $('.ingridient-modal__overlay').hide()
    })


    $('.rezept-slider__item').click(function() {
        let modalID = $(this).data('rezeptmodal')
        $('#' + modalID).show()
        $('.rezept-modal__overlay').show()
    })
    $('.rezept-modal__close').click(function() {
        $('.rezept-modal').hide()
        $('.rezept-modal__overlay').hide()
    })

    /////////////SHRINK NAVI /////////////
    //Shrink Menu
    var lastScrollTop = 0;
    let root = document.documentElement;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();

            if (st > 100) {
                $(".main-header").addClass("main-header--scrolled");
            } else {
                $(".main-header").removeClass("main-header--scrolled");

            }
        
        lastScrollTop = st;
    });


})